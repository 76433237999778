import { assertNever } from "../../util/assertNever";
import { GuestLanguage } from "./GuestLanguage";
import { Language } from "./Language";
import { LanguageIsoCode } from "./LanguageIsoCode";
import { WidgetLanguage } from "./WidgetLanguage";

export const mapLanguageToLanguageIsoCode = (value: Language): null | LanguageIsoCode => {
  switch (value) {
    case Language.NONE:
      return null;
    case Language.GERMAN:
      return LanguageIsoCode.GERMAN;
    case Language.ITALIAN:
      return LanguageIsoCode.ITALIAN;
    case Language.ENGLISH:
      return LanguageIsoCode.ENGLISH;
    case Language.DUTCH:
      return LanguageIsoCode.DUTCH_FLEMISH;
    case Language.RUSSIAN:
      return LanguageIsoCode.RUSSIAN;
    case Language.POLISH:
      return LanguageIsoCode.POLISH;
    case Language.HUNGARIAN:
      return LanguageIsoCode.HUNGARIAN;
    case Language.CZECH:
      return LanguageIsoCode.CZECH;
    case Language.AUSTRIAN:
      return LanguageIsoCode.GERMAN;
    case Language.SWISS:
      return LanguageIsoCode.GERMAN;
    case Language.BELGIAN:
      return LanguageIsoCode.DUTCH_FLEMISH;
    case Language.DANISH:
      return LanguageIsoCode.DANISH;
    case Language.FRENCH:
      return LanguageIsoCode.FRENCH;
    case Language.CROATIAN:
      return LanguageIsoCode.CROATIAN;
    case Language.LIECHTENSTEIN:
      return LanguageIsoCode.GERMAN;
    case Language.SWEDISH:
      return LanguageIsoCode.SWEDISH;
    default:
      assertNever(value);
  }
};

export const mapGuestLanguageToLanguageIsoCode = (value: GuestLanguage): null | LanguageIsoCode => {
  switch (value) {
    case GuestLanguage.NONE:
      return null;
    case GuestLanguage.GERMAN:
      return LanguageIsoCode.GERMAN;
    case GuestLanguage.ITALIAN:
      return LanguageIsoCode.ITALIAN;
    case GuestLanguage.ENGLISH:
      return LanguageIsoCode.ENGLISH;
    case GuestLanguage.BULGARIAN:
      return LanguageIsoCode.BULGARIAN;
    case GuestLanguage.CROATIAN:
      return LanguageIsoCode.CROATIAN;
    case GuestLanguage.DUTCH:
      return LanguageIsoCode.DUTCH_FLEMISH;
    case GuestLanguage.ESTONIAN:
      return LanguageIsoCode.ESTONIAN;
    case GuestLanguage.FINNISH:
      return LanguageIsoCode.FINNISH;
    case GuestLanguage.GREEK:
      return LanguageIsoCode.GREEK_MODERN_1453_;
    case GuestLanguage.IRISH:
      return LanguageIsoCode.IRISH;
    case GuestLanguage.LATVIAN:
      return LanguageIsoCode.LATVIAN;
    case GuestLanguage.LITHUANIAN:
      return LanguageIsoCode.LITHUANIAN;
    case GuestLanguage.RUSSIAN:
      return LanguageIsoCode.RUSSIAN;
    case GuestLanguage.POLISH:
      return LanguageIsoCode.POLISH;
    case GuestLanguage.PORTUGUESE:
      return LanguageIsoCode.PORTUGUESE;
    case GuestLanguage.ROMANIAN:
      return LanguageIsoCode.ROMANIAN_MOLDAVIAN_MOLDOVAN;
    case GuestLanguage.SLOVAK:
      return LanguageIsoCode.SLOVAK;
    case GuestLanguage.SLOVENIAN:
      return LanguageIsoCode.SLOVENIAN;
    case GuestLanguage.SPANISH:
      return LanguageIsoCode.SPANISH_CASTILIAN;
    case GuestLanguage.JAPANESE:
      return LanguageIsoCode.JAPANESE;
    case GuestLanguage.HUNGARIAN:
      return LanguageIsoCode.HUNGARIAN;
    case GuestLanguage.CZECH:
      return LanguageIsoCode.CZECH;
    case GuestLanguage.DANISH:
      return LanguageIsoCode.DANISH;
    case GuestLanguage.FRENCH:
      return LanguageIsoCode.FRENCH;
    case GuestLanguage.SWEDISH:
      return LanguageIsoCode.SWEDISH;
    default:
      assertNever(value);
  }
};

//#region map from iso code
export const mapIsoCodeToLanguage = (value: LanguageIsoCode): Language => {
  switch (value) {
    case LanguageIsoCode.CROATIAN:
      return Language.CROATIAN;
    case LanguageIsoCode.CZECH:
      return Language.CZECH;
    case LanguageIsoCode.DANISH:
      return Language.DANISH;
    case LanguageIsoCode.DUTCH_FLEMISH:
      return Language.DUTCH;
    case LanguageIsoCode.ENGLISH:
      return Language.ENGLISH;
    case LanguageIsoCode.FRENCH:
      return Language.FRENCH;
    case LanguageIsoCode.GERMAN:
      return Language.GERMAN;
    case LanguageIsoCode.HUNGARIAN:
      return Language.HUNGARIAN;
    case LanguageIsoCode.ITALIAN:
      return Language.ITALIAN;
    case LanguageIsoCode.POLISH:
      return Language.POLISH;
    case LanguageIsoCode.RUSSIAN:
      return Language.RUSSIAN;
    case LanguageIsoCode.SWEDISH:
      return Language.SWEDISH;

    case LanguageIsoCode.ABKHAZIAN:
    case LanguageIsoCode.AFAR:
    case LanguageIsoCode.AFRIKAANS:
    case LanguageIsoCode.AKAN:
    case LanguageIsoCode.ALBANIAN:
    case LanguageIsoCode.AMHARIC:
    case LanguageIsoCode.ARABIC:
    case LanguageIsoCode.ARAGONESE:
    case LanguageIsoCode.ARMENIAN:
    case LanguageIsoCode.ASSAMESE:
    case LanguageIsoCode.AVARIC:
    case LanguageIsoCode.AVESTAN:
    case LanguageIsoCode.AYMARA:
    case LanguageIsoCode.AZERBAIJANI:
    case LanguageIsoCode.BAMBARA:
    case LanguageIsoCode.BASHKIR:
    case LanguageIsoCode.BASQUE:
    case LanguageIsoCode.BELARUSIAN:
    case LanguageIsoCode.BENGALI:
    case LanguageIsoCode.BIHARI_LANGUAGES:
    case LanguageIsoCode.BISLAMA:
    case LanguageIsoCode.BOKM_L_NORWEGIAN_NORWEGIAN_BOKM_L:
    case LanguageIsoCode.BOSNIAN:
    case LanguageIsoCode.BRETON:
    case LanguageIsoCode.BULGARIAN:
    case LanguageIsoCode.BURMESE:
    case LanguageIsoCode.CATALAN_VALENCIAN:
    case LanguageIsoCode.CENTRAL_KHMER:
    case LanguageIsoCode.CHAMORRO:
    case LanguageIsoCode.CHECHEN:
    case LanguageIsoCode.CHICHEWA_CHEWA_NYANJA:
    case LanguageIsoCode.CHINESE:
    case LanguageIsoCode.CHURCH_SLAVIC_OLD_SLAVONIC_CHURCH_SLAVONIC_OLD_BULGARIAN_OLD_CHURCH_SLAVONIC:
    case LanguageIsoCode.CHUVASH:
    case LanguageIsoCode.CORNISH:
    case LanguageIsoCode.CORSICAN:
    case LanguageIsoCode.CREE:
    case LanguageIsoCode.DIVEHI_DHIVEHI_MALDIVIAN:
    case LanguageIsoCode.DZONGKHA:
    case LanguageIsoCode.TAGALOG:
    case LanguageIsoCode.TAHITIAN:
    case LanguageIsoCode.TAJIK:
    case LanguageIsoCode.TAMIL:
    case LanguageIsoCode.TATAR:
    case LanguageIsoCode.TELUGU:
    case LanguageIsoCode.THAI:
    case LanguageIsoCode.TIBETAN:
    case LanguageIsoCode.TIGRINYA:
    case LanguageIsoCode.TONGA_TONGA_ISLANDS_:
    case LanguageIsoCode.TSONGA:
    case LanguageIsoCode.TSWANA:
    case LanguageIsoCode.TURKISH:
    case LanguageIsoCode.TURKMEN:
    case LanguageIsoCode.TWI:
    case LanguageIsoCode.UIGHUR_UYGHUR:
    case LanguageIsoCode.UKRAINIAN:
    case LanguageIsoCode.URDU:
    case LanguageIsoCode.UZBEK:
    case LanguageIsoCode.VENDA:
    case LanguageIsoCode.VIETNAMESE:
    case LanguageIsoCode.VOLAP_K:
    case LanguageIsoCode.WALLOON:
    case LanguageIsoCode.WELSH:
    case LanguageIsoCode.WESTERN_FRISIAN:
    case LanguageIsoCode.WOLOF:
    case LanguageIsoCode.XHOSA:
    case LanguageIsoCode.YIDDISH:
    case LanguageIsoCode.YORUBA:
    case LanguageIsoCode.ZHUANG_CHUANG:
    case LanguageIsoCode.ZULU:
    case LanguageIsoCode.SAMOAN:
    case LanguageIsoCode.SANGO:
    case LanguageIsoCode.SANSKRIT:
    case LanguageIsoCode.SARDINIAN:
    case LanguageIsoCode.SERBIAN:
    case LanguageIsoCode.SHONA:
    case LanguageIsoCode.SICHUAN_YI_NUOSU:
    case LanguageIsoCode.SINDHI:
    case LanguageIsoCode.SINHALA_SINHALESE:
    case LanguageIsoCode.SLOVAK:
    case LanguageIsoCode.SLOVENIAN:
    case LanguageIsoCode.SOMALI:
    case LanguageIsoCode.SOTHO_SOUTHERN:
    case LanguageIsoCode.SPANISH_CASTILIAN:
    case LanguageIsoCode.SUNDANESE:
    case LanguageIsoCode.SWAHILI:
    case LanguageIsoCode.SWATI:
    case LanguageIsoCode.JAPANESE:
    case LanguageIsoCode.JAVANESE:
    case LanguageIsoCode.KALAALLISUT_GREENLANDIC:
    case LanguageIsoCode.KANNADA:
    case LanguageIsoCode.KANURI:
    case LanguageIsoCode.KASHMIRI:
    case LanguageIsoCode.KAZAKH:
    case LanguageIsoCode.KIKUYU_GIKUYU:
    case LanguageIsoCode.KINYARWANDA:
    case LanguageIsoCode.KIRGHIZ_KYRGYZ:
    case LanguageIsoCode.KOMI:
    case LanguageIsoCode.KONGO:
    case LanguageIsoCode.KOREAN:
    case LanguageIsoCode.KUANYAMA_KWANYAMA:
    case LanguageIsoCode.KURDISH:
    case LanguageIsoCode.LAO:
    case LanguageIsoCode.LATIN:
    case LanguageIsoCode.LATVIAN:
    case LanguageIsoCode.LIMBURGAN_LIMBURGER_LIMBURGISH:
    case LanguageIsoCode.LINGALA:
    case LanguageIsoCode.LITHUANIAN:
    case LanguageIsoCode.LUBA_KATANGA:
    case LanguageIsoCode.LUXEMBOURGISH_LETZEBURGESCH:
    case LanguageIsoCode.MACEDONIAN:
    case LanguageIsoCode.MALAGASY:
    case LanguageIsoCode.MALAY:
    case LanguageIsoCode.MALAYALAM:
    case LanguageIsoCode.MALTESE:
    case LanguageIsoCode.MANX:
    case LanguageIsoCode.MAORI:
    case LanguageIsoCode.MARATHI:
    case LanguageIsoCode.MARSHALLESE:
    case LanguageIsoCode.MONGOLIAN:
    case LanguageIsoCode.NAURU:
    case LanguageIsoCode.NAVAJO_NAVAHO:
    case LanguageIsoCode.NDEBELE_NORTH_NORTH_NDEBELE:
    case LanguageIsoCode.NDEBELE_SOUTH_SOUTH_NDEBELE:
    case LanguageIsoCode.NDONGA:
    case LanguageIsoCode.NEPALI:
    case LanguageIsoCode.NORTHERN_SAMI:
    case LanguageIsoCode.NORWEGIAN:
    case LanguageIsoCode.NORWEGIAN_NYNORSK_NYNORSK_NORWEGIAN:
    case LanguageIsoCode.OCCITAN_POST_1500_:
    case LanguageIsoCode.OJIBWA:
    case LanguageIsoCode.ORIYA:
    case LanguageIsoCode.OROMO:
    case LanguageIsoCode.OSSETIAN_OSSETIC:
    case LanguageIsoCode.PALI:
    case LanguageIsoCode.PANJABI_PUNJABI:
    case LanguageIsoCode.PERSIAN:
    case LanguageIsoCode.PORTUGUESE:
    case LanguageIsoCode.PUSHTO_PASHTO:
    case LanguageIsoCode.QUECHUA:
    case LanguageIsoCode.ROMANIAN_MOLDAVIAN_MOLDOVAN:
    case LanguageIsoCode.ROMANSH:
    case LanguageIsoCode.RUNDI:
    case LanguageIsoCode.ICELANDIC:
    case LanguageIsoCode.IDO:
    case LanguageIsoCode.IGBO:
    case LanguageIsoCode.INDONESIAN:
    case LanguageIsoCode.INTERLINGUA_INTERNATIONAL_AUXILIARY_LANGUAGE_ASSOCIATION_:
    case LanguageIsoCode.INTERLINGUE_OCCIDENTAL:
    case LanguageIsoCode.INUKTITUT:
    case LanguageIsoCode.INUPIAQ:
    case LanguageIsoCode.IRISH:
    case LanguageIsoCode.GREEK_MODERN_1453_:
    case LanguageIsoCode.GUARANI:
    case LanguageIsoCode.GUJARATI:
    case LanguageIsoCode.HAITIAN_HAITIAN_CREOLE:
    case LanguageIsoCode.HAUSA:
    case LanguageIsoCode.HEBREW:
    case LanguageIsoCode.HERERO:
    case LanguageIsoCode.HINDI:
    case LanguageIsoCode.HIRI_MOTU:
    case LanguageIsoCode.FULAH:
    case LanguageIsoCode.GAELIC_SCOTTISH_GAELIC:
    case LanguageIsoCode.GALICIAN:
    case LanguageIsoCode.GANDA:
    case LanguageIsoCode.GEORGIAN:
    case LanguageIsoCode.ESPERANTO:
    case LanguageIsoCode.ESTONIAN:
    case LanguageIsoCode.EWE:
    case LanguageIsoCode.FAROESE:
    case LanguageIsoCode.FIJIAN:
    case LanguageIsoCode.FINNISH:
      return Language.ENGLISH;
    default:
      assertNever(value);
  }
};

export const mapIsoCodeToGuestLanguage = (value: LanguageIsoCode): GuestLanguage => {
  switch (value) {
    case LanguageIsoCode.BULGARIAN:
      return GuestLanguage.BULGARIAN;
    case LanguageIsoCode.CROATIAN:
      return GuestLanguage.CROATIAN;
    case LanguageIsoCode.CZECH:
      return GuestLanguage.CZECH;
    case LanguageIsoCode.DANISH:
      return GuestLanguage.DANISH;
    case LanguageIsoCode.DUTCH_FLEMISH:
      return GuestLanguage.DUTCH;
    case LanguageIsoCode.ENGLISH:
      return GuestLanguage.ENGLISH;
    case LanguageIsoCode.ESTONIAN:
      return GuestLanguage.ESTONIAN;
    case LanguageIsoCode.FINNISH:
      return GuestLanguage.FINNISH;
    case LanguageIsoCode.FRENCH:
      return GuestLanguage.FRENCH;
    case LanguageIsoCode.GERMAN:
      return GuestLanguage.GERMAN;
    case LanguageIsoCode.GREEK_MODERN_1453_:
      return GuestLanguage.GREEK;
    case LanguageIsoCode.HUNGARIAN:
      return GuestLanguage.HUNGARIAN;
    case LanguageIsoCode.IRISH:
      return GuestLanguage.IRISH;
    case LanguageIsoCode.ITALIAN:
      return GuestLanguage.ITALIAN;
    case LanguageIsoCode.JAPANESE:
      return GuestLanguage.JAPANESE;
    case LanguageIsoCode.LATVIAN:
      return GuestLanguage.LATVIAN;
    case LanguageIsoCode.LITHUANIAN:
      return GuestLanguage.LITHUANIAN;
    case LanguageIsoCode.POLISH:
      return GuestLanguage.POLISH;
    case LanguageIsoCode.PORTUGUESE:
      return GuestLanguage.PORTUGUESE;
    case LanguageIsoCode.ROMANIAN_MOLDAVIAN_MOLDOVAN:
      return GuestLanguage.ROMANIAN;
    case LanguageIsoCode.RUSSIAN:
      return GuestLanguage.RUSSIAN;
    case LanguageIsoCode.SLOVAK:
      return GuestLanguage.SLOVAK;
    case LanguageIsoCode.SLOVENIAN:
      return GuestLanguage.SLOVENIAN;
    case LanguageIsoCode.SPANISH_CASTILIAN:
      return GuestLanguage.SPANISH;
    case LanguageIsoCode.SWEDISH:
      return GuestLanguage.SWEDISH;

    case LanguageIsoCode.ABKHAZIAN:
    case LanguageIsoCode.AFAR:
    case LanguageIsoCode.AFRIKAANS:
    case LanguageIsoCode.AKAN:
    case LanguageIsoCode.ALBANIAN:
    case LanguageIsoCode.AMHARIC:
    case LanguageIsoCode.ARABIC:
    case LanguageIsoCode.ARAGONESE:
    case LanguageIsoCode.ARMENIAN:
    case LanguageIsoCode.ASSAMESE:
    case LanguageIsoCode.AVARIC:
    case LanguageIsoCode.AVESTAN:
    case LanguageIsoCode.AYMARA:
    case LanguageIsoCode.AZERBAIJANI:
    case LanguageIsoCode.BAMBARA:
    case LanguageIsoCode.BASHKIR:
    case LanguageIsoCode.BASQUE:
    case LanguageIsoCode.BELARUSIAN:
    case LanguageIsoCode.BENGALI:
    case LanguageIsoCode.BIHARI_LANGUAGES:
    case LanguageIsoCode.BISLAMA:
    case LanguageIsoCode.BOKM_L_NORWEGIAN_NORWEGIAN_BOKM_L:
    case LanguageIsoCode.BOSNIAN:
    case LanguageIsoCode.BRETON:
    case LanguageIsoCode.BURMESE:
    case LanguageIsoCode.CATALAN_VALENCIAN:
    case LanguageIsoCode.CENTRAL_KHMER:
    case LanguageIsoCode.CHAMORRO:
    case LanguageIsoCode.CHECHEN:
    case LanguageIsoCode.CHICHEWA_CHEWA_NYANJA:
    case LanguageIsoCode.CHINESE:
    case LanguageIsoCode.CHURCH_SLAVIC_OLD_SLAVONIC_CHURCH_SLAVONIC_OLD_BULGARIAN_OLD_CHURCH_SLAVONIC:
    case LanguageIsoCode.CHUVASH:
    case LanguageIsoCode.CORNISH:
    case LanguageIsoCode.CORSICAN:
    case LanguageIsoCode.CREE:
    case LanguageIsoCode.DIVEHI_DHIVEHI_MALDIVIAN:
    case LanguageIsoCode.DZONGKHA:
    case LanguageIsoCode.TAGALOG:
    case LanguageIsoCode.TAHITIAN:
    case LanguageIsoCode.TAJIK:
    case LanguageIsoCode.TAMIL:
    case LanguageIsoCode.TATAR:
    case LanguageIsoCode.TELUGU:
    case LanguageIsoCode.THAI:
    case LanguageIsoCode.TIBETAN:
    case LanguageIsoCode.TIGRINYA:
    case LanguageIsoCode.TONGA_TONGA_ISLANDS_:
    case LanguageIsoCode.TSONGA:
    case LanguageIsoCode.TSWANA:
    case LanguageIsoCode.TURKISH:
    case LanguageIsoCode.TURKMEN:
    case LanguageIsoCode.TWI:
    case LanguageIsoCode.UIGHUR_UYGHUR:
    case LanguageIsoCode.UKRAINIAN:
    case LanguageIsoCode.URDU:
    case LanguageIsoCode.UZBEK:
    case LanguageIsoCode.VENDA:
    case LanguageIsoCode.VIETNAMESE:
    case LanguageIsoCode.VOLAP_K:
    case LanguageIsoCode.WALLOON:
    case LanguageIsoCode.WELSH:
    case LanguageIsoCode.WESTERN_FRISIAN:
    case LanguageIsoCode.WOLOF:
    case LanguageIsoCode.XHOSA:
    case LanguageIsoCode.YIDDISH:
    case LanguageIsoCode.YORUBA:
    case LanguageIsoCode.ZHUANG_CHUANG:
    case LanguageIsoCode.ZULU:
    case LanguageIsoCode.SAMOAN:
    case LanguageIsoCode.SANGO:
    case LanguageIsoCode.SANSKRIT:
    case LanguageIsoCode.SARDINIAN:
    case LanguageIsoCode.SERBIAN:
    case LanguageIsoCode.SHONA:
    case LanguageIsoCode.SICHUAN_YI_NUOSU:
    case LanguageIsoCode.SINDHI:
    case LanguageIsoCode.SINHALA_SINHALESE:
    case LanguageIsoCode.SOMALI:
    case LanguageIsoCode.SOTHO_SOUTHERN:
    case LanguageIsoCode.SUNDANESE:
    case LanguageIsoCode.SWAHILI:
    case LanguageIsoCode.SWATI:
    case LanguageIsoCode.JAVANESE:
    case LanguageIsoCode.KALAALLISUT_GREENLANDIC:
    case LanguageIsoCode.KANNADA:
    case LanguageIsoCode.KANURI:
    case LanguageIsoCode.KASHMIRI:
    case LanguageIsoCode.KAZAKH:
    case LanguageIsoCode.KIKUYU_GIKUYU:
    case LanguageIsoCode.KINYARWANDA:
    case LanguageIsoCode.KIRGHIZ_KYRGYZ:
    case LanguageIsoCode.KOMI:
    case LanguageIsoCode.KONGO:
    case LanguageIsoCode.KOREAN:
    case LanguageIsoCode.KUANYAMA_KWANYAMA:
    case LanguageIsoCode.KURDISH:
    case LanguageIsoCode.LAO:
    case LanguageIsoCode.LATIN:
    case LanguageIsoCode.LIMBURGAN_LIMBURGER_LIMBURGISH:
    case LanguageIsoCode.LINGALA:
    case LanguageIsoCode.LUBA_KATANGA:
    case LanguageIsoCode.LUXEMBOURGISH_LETZEBURGESCH:
    case LanguageIsoCode.MACEDONIAN:
    case LanguageIsoCode.MALAGASY:
    case LanguageIsoCode.MALAY:
    case LanguageIsoCode.MALAYALAM:
    case LanguageIsoCode.MALTESE:
    case LanguageIsoCode.MANX:
    case LanguageIsoCode.MAORI:
    case LanguageIsoCode.MARATHI:
    case LanguageIsoCode.MARSHALLESE:
    case LanguageIsoCode.MONGOLIAN:
    case LanguageIsoCode.NAURU:
    case LanguageIsoCode.NAVAJO_NAVAHO:
    case LanguageIsoCode.NDEBELE_NORTH_NORTH_NDEBELE:
    case LanguageIsoCode.NDEBELE_SOUTH_SOUTH_NDEBELE:
    case LanguageIsoCode.NDONGA:
    case LanguageIsoCode.NEPALI:
    case LanguageIsoCode.NORTHERN_SAMI:
    case LanguageIsoCode.NORWEGIAN:
    case LanguageIsoCode.NORWEGIAN_NYNORSK_NYNORSK_NORWEGIAN:
    case LanguageIsoCode.OCCITAN_POST_1500_:
    case LanguageIsoCode.OJIBWA:
    case LanguageIsoCode.ORIYA:
    case LanguageIsoCode.OROMO:
    case LanguageIsoCode.OSSETIAN_OSSETIC:
    case LanguageIsoCode.PALI:
    case LanguageIsoCode.PANJABI_PUNJABI:
    case LanguageIsoCode.PERSIAN:
    case LanguageIsoCode.PUSHTO_PASHTO:
    case LanguageIsoCode.QUECHUA:
    case LanguageIsoCode.ROMANSH:
    case LanguageIsoCode.RUNDI:
    case LanguageIsoCode.ICELANDIC:
    case LanguageIsoCode.IDO:
    case LanguageIsoCode.IGBO:
    case LanguageIsoCode.INDONESIAN:
    case LanguageIsoCode.INTERLINGUA_INTERNATIONAL_AUXILIARY_LANGUAGE_ASSOCIATION_:
    case LanguageIsoCode.INTERLINGUE_OCCIDENTAL:
    case LanguageIsoCode.INUKTITUT:
    case LanguageIsoCode.INUPIAQ:
    case LanguageIsoCode.GUARANI:
    case LanguageIsoCode.GUJARATI:
    case LanguageIsoCode.HAITIAN_HAITIAN_CREOLE:
    case LanguageIsoCode.HAUSA:
    case LanguageIsoCode.HEBREW:
    case LanguageIsoCode.HERERO:
    case LanguageIsoCode.HINDI:
    case LanguageIsoCode.HIRI_MOTU:
    case LanguageIsoCode.FULAH:
    case LanguageIsoCode.GAELIC_SCOTTISH_GAELIC:
    case LanguageIsoCode.GALICIAN:
    case LanguageIsoCode.GANDA:
    case LanguageIsoCode.GEORGIAN:
    case LanguageIsoCode.ESPERANTO:
    case LanguageIsoCode.EWE:
    case LanguageIsoCode.FAROESE:
    case LanguageIsoCode.FIJIAN:
      return GuestLanguage.ENGLISH;
    default:
      assertNever(value);
  }
};

export const mapIsoCodeToWidgetLanguage = (value: LanguageIsoCode): WidgetLanguage => {
  switch (value) {
    case LanguageIsoCode.ENGLISH:
      return WidgetLanguage.ENGLISH;
    case LanguageIsoCode.GERMAN:
      return WidgetLanguage.GERMAN;
    case LanguageIsoCode.ITALIAN:
      return WidgetLanguage.ITALIAN;

    case LanguageIsoCode.CROATIAN:
    case LanguageIsoCode.CZECH:
    case LanguageIsoCode.DANISH:
    case LanguageIsoCode.DUTCH_FLEMISH:
    case LanguageIsoCode.FRENCH:
    case LanguageIsoCode.HUNGARIAN:
    case LanguageIsoCode.POLISH:
    case LanguageIsoCode.RUSSIAN:
    case LanguageIsoCode.SWEDISH:
    case LanguageIsoCode.ABKHAZIAN:
    case LanguageIsoCode.AFAR:
    case LanguageIsoCode.AFRIKAANS:
    case LanguageIsoCode.AKAN:
    case LanguageIsoCode.ALBANIAN:
    case LanguageIsoCode.AMHARIC:
    case LanguageIsoCode.ARABIC:
    case LanguageIsoCode.ARAGONESE:
    case LanguageIsoCode.ARMENIAN:
    case LanguageIsoCode.ASSAMESE:
    case LanguageIsoCode.AVARIC:
    case LanguageIsoCode.AVESTAN:
    case LanguageIsoCode.AYMARA:
    case LanguageIsoCode.AZERBAIJANI:
    case LanguageIsoCode.BAMBARA:
    case LanguageIsoCode.BASHKIR:
    case LanguageIsoCode.BASQUE:
    case LanguageIsoCode.BELARUSIAN:
    case LanguageIsoCode.BENGALI:
    case LanguageIsoCode.BIHARI_LANGUAGES:
    case LanguageIsoCode.BISLAMA:
    case LanguageIsoCode.BOKM_L_NORWEGIAN_NORWEGIAN_BOKM_L:
    case LanguageIsoCode.BOSNIAN:
    case LanguageIsoCode.BRETON:
    case LanguageIsoCode.BULGARIAN:
    case LanguageIsoCode.BURMESE:
    case LanguageIsoCode.CATALAN_VALENCIAN:
    case LanguageIsoCode.CENTRAL_KHMER:
    case LanguageIsoCode.CHAMORRO:
    case LanguageIsoCode.CHECHEN:
    case LanguageIsoCode.CHICHEWA_CHEWA_NYANJA:
    case LanguageIsoCode.CHINESE:
    case LanguageIsoCode.CHURCH_SLAVIC_OLD_SLAVONIC_CHURCH_SLAVONIC_OLD_BULGARIAN_OLD_CHURCH_SLAVONIC:
    case LanguageIsoCode.CHUVASH:
    case LanguageIsoCode.CORNISH:
    case LanguageIsoCode.CORSICAN:
    case LanguageIsoCode.CREE:
    case LanguageIsoCode.DIVEHI_DHIVEHI_MALDIVIAN:
    case LanguageIsoCode.DZONGKHA:
    case LanguageIsoCode.TAGALOG:
    case LanguageIsoCode.TAHITIAN:
    case LanguageIsoCode.TAJIK:
    case LanguageIsoCode.TAMIL:
    case LanguageIsoCode.TATAR:
    case LanguageIsoCode.TELUGU:
    case LanguageIsoCode.THAI:
    case LanguageIsoCode.TIBETAN:
    case LanguageIsoCode.TIGRINYA:
    case LanguageIsoCode.TONGA_TONGA_ISLANDS_:
    case LanguageIsoCode.TSONGA:
    case LanguageIsoCode.TSWANA:
    case LanguageIsoCode.TURKISH:
    case LanguageIsoCode.TURKMEN:
    case LanguageIsoCode.TWI:
    case LanguageIsoCode.UIGHUR_UYGHUR:
    case LanguageIsoCode.UKRAINIAN:
    case LanguageIsoCode.URDU:
    case LanguageIsoCode.UZBEK:
    case LanguageIsoCode.VENDA:
    case LanguageIsoCode.VIETNAMESE:
    case LanguageIsoCode.VOLAP_K:
    case LanguageIsoCode.WALLOON:
    case LanguageIsoCode.WELSH:
    case LanguageIsoCode.WESTERN_FRISIAN:
    case LanguageIsoCode.WOLOF:
    case LanguageIsoCode.XHOSA:
    case LanguageIsoCode.YIDDISH:
    case LanguageIsoCode.YORUBA:
    case LanguageIsoCode.ZHUANG_CHUANG:
    case LanguageIsoCode.ZULU:
    case LanguageIsoCode.SAMOAN:
    case LanguageIsoCode.SANGO:
    case LanguageIsoCode.SANSKRIT:
    case LanguageIsoCode.SARDINIAN:
    case LanguageIsoCode.SERBIAN:
    case LanguageIsoCode.SHONA:
    case LanguageIsoCode.SICHUAN_YI_NUOSU:
    case LanguageIsoCode.SINDHI:
    case LanguageIsoCode.SINHALA_SINHALESE:
    case LanguageIsoCode.SLOVAK:
    case LanguageIsoCode.SLOVENIAN:
    case LanguageIsoCode.SOMALI:
    case LanguageIsoCode.SOTHO_SOUTHERN:
    case LanguageIsoCode.SPANISH_CASTILIAN:
    case LanguageIsoCode.SUNDANESE:
    case LanguageIsoCode.SWAHILI:
    case LanguageIsoCode.SWATI:
    case LanguageIsoCode.JAPANESE:
    case LanguageIsoCode.JAVANESE:
    case LanguageIsoCode.KALAALLISUT_GREENLANDIC:
    case LanguageIsoCode.KANNADA:
    case LanguageIsoCode.KANURI:
    case LanguageIsoCode.KASHMIRI:
    case LanguageIsoCode.KAZAKH:
    case LanguageIsoCode.KIKUYU_GIKUYU:
    case LanguageIsoCode.KINYARWANDA:
    case LanguageIsoCode.KIRGHIZ_KYRGYZ:
    case LanguageIsoCode.KOMI:
    case LanguageIsoCode.KONGO:
    case LanguageIsoCode.KOREAN:
    case LanguageIsoCode.KUANYAMA_KWANYAMA:
    case LanguageIsoCode.KURDISH:
    case LanguageIsoCode.LAO:
    case LanguageIsoCode.LATIN:
    case LanguageIsoCode.LATVIAN:
    case LanguageIsoCode.LIMBURGAN_LIMBURGER_LIMBURGISH:
    case LanguageIsoCode.LINGALA:
    case LanguageIsoCode.LITHUANIAN:
    case LanguageIsoCode.LUBA_KATANGA:
    case LanguageIsoCode.LUXEMBOURGISH_LETZEBURGESCH:
    case LanguageIsoCode.MACEDONIAN:
    case LanguageIsoCode.MALAGASY:
    case LanguageIsoCode.MALAY:
    case LanguageIsoCode.MALAYALAM:
    case LanguageIsoCode.MALTESE:
    case LanguageIsoCode.MANX:
    case LanguageIsoCode.MAORI:
    case LanguageIsoCode.MARATHI:
    case LanguageIsoCode.MARSHALLESE:
    case LanguageIsoCode.MONGOLIAN:
    case LanguageIsoCode.NAURU:
    case LanguageIsoCode.NAVAJO_NAVAHO:
    case LanguageIsoCode.NDEBELE_NORTH_NORTH_NDEBELE:
    case LanguageIsoCode.NDEBELE_SOUTH_SOUTH_NDEBELE:
    case LanguageIsoCode.NDONGA:
    case LanguageIsoCode.NEPALI:
    case LanguageIsoCode.NORTHERN_SAMI:
    case LanguageIsoCode.NORWEGIAN:
    case LanguageIsoCode.NORWEGIAN_NYNORSK_NYNORSK_NORWEGIAN:
    case LanguageIsoCode.OCCITAN_POST_1500_:
    case LanguageIsoCode.OJIBWA:
    case LanguageIsoCode.ORIYA:
    case LanguageIsoCode.OROMO:
    case LanguageIsoCode.OSSETIAN_OSSETIC:
    case LanguageIsoCode.PALI:
    case LanguageIsoCode.PANJABI_PUNJABI:
    case LanguageIsoCode.PERSIAN:
    case LanguageIsoCode.PORTUGUESE:
    case LanguageIsoCode.PUSHTO_PASHTO:
    case LanguageIsoCode.QUECHUA:
    case LanguageIsoCode.ROMANIAN_MOLDAVIAN_MOLDOVAN:
    case LanguageIsoCode.ROMANSH:
    case LanguageIsoCode.RUNDI:
    case LanguageIsoCode.ICELANDIC:
    case LanguageIsoCode.IDO:
    case LanguageIsoCode.IGBO:
    case LanguageIsoCode.INDONESIAN:
    case LanguageIsoCode.INTERLINGUA_INTERNATIONAL_AUXILIARY_LANGUAGE_ASSOCIATION_:
    case LanguageIsoCode.INTERLINGUE_OCCIDENTAL:
    case LanguageIsoCode.INUKTITUT:
    case LanguageIsoCode.INUPIAQ:
    case LanguageIsoCode.IRISH:
    case LanguageIsoCode.GREEK_MODERN_1453_:
    case LanguageIsoCode.GUARANI:
    case LanguageIsoCode.GUJARATI:
    case LanguageIsoCode.HAITIAN_HAITIAN_CREOLE:
    case LanguageIsoCode.HAUSA:
    case LanguageIsoCode.HEBREW:
    case LanguageIsoCode.HERERO:
    case LanguageIsoCode.HINDI:
    case LanguageIsoCode.HIRI_MOTU:
    case LanguageIsoCode.FULAH:
    case LanguageIsoCode.GAELIC_SCOTTISH_GAELIC:
    case LanguageIsoCode.GALICIAN:
    case LanguageIsoCode.GANDA:
    case LanguageIsoCode.GEORGIAN:
    case LanguageIsoCode.ESPERANTO:
    case LanguageIsoCode.ESTONIAN:
    case LanguageIsoCode.EWE:
    case LanguageIsoCode.FAROESE:
    case LanguageIsoCode.FIJIAN:
    case LanguageIsoCode.FINNISH:
      return WidgetLanguage.ENGLISH;
    default:
      assertNever(value);
  }
};
//#endregion

//#region map from widget language
export const mapWidgetLanguageToLanguageIsoCode = (value: WidgetLanguage): LanguageIsoCode => {
  switch (value) {
    case WidgetLanguage.GERMAN:
      return LanguageIsoCode.GERMAN;
    case WidgetLanguage.ITALIAN:
      return LanguageIsoCode.ITALIAN;
    case WidgetLanguage.ENGLISH:
      return LanguageIsoCode.ENGLISH;
    default:
      assertNever(value);
  }
};

export const mapWidgetLanguageToLanguage = (value: WidgetLanguage): Language => {
  switch (value) {
    case WidgetLanguage.GERMAN:
      return Language.GERMAN;
    case WidgetLanguage.ITALIAN:
      return Language.ITALIAN;
    case WidgetLanguage.ENGLISH:
      return Language.ENGLISH;
    default:
      assertNever(value);
  }
};

export const mapWidgetLanguageToGuestLanguage = (value: WidgetLanguage): GuestLanguage => {
  switch (value) {
    case WidgetLanguage.GERMAN:
      return GuestLanguage.GERMAN;
    case WidgetLanguage.ITALIAN:
      return GuestLanguage.ITALIAN;
    case WidgetLanguage.ENGLISH:
      return GuestLanguage.ENGLISH;
    default:
      assertNever(value);
  }
};
//#endregion
