import { GenericFailure } from "./GenericFailure";

export class CustomValidationError extends GenericFailure {
  constructor(genericFailure: GenericFailure) {
    super({
      message: genericFailure.message,
      statusCode: genericFailure.statusCode,
      description: genericFailure.description,
      errors: genericFailure.errors,
      logContext: genericFailure.logContext,
      messageWithSensitiveData: genericFailure.messageWithSensitiveData,
    });

    this.name = "ValidationError";
  }
}
