import { reactive, toRefs } from "vue";

const localAppState: {
  initialPingDone: boolean;
} = reactive({
  initialPingDone: false,
});

const setInitialPingDone = ({ initialPingState }: { initialPingState: boolean }) => {
  localAppState.initialPingDone = initialPingState;
};

export const useApp = () => {
  return {
    ...toRefs(localAppState),

    setInitialPingDone,
  };
};
