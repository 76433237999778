export enum Module {
  CRM_SYNC = "crm_sync",
  NEWSLETTER_SYNC = "newsletter_sync",
  CONNECT = "connect",
  REQUEST = "request",
  YANOVIS = "yanovis",
  LTS_TICKETING = "lts_ticketing",
  DISCOUNT_CODE = "discount_code",
  LTS_ASTAT = "lts_astat",
  REST_API = "rest_api",
  CHECKIN = "checkin",
  VOUCHER = "voucher",
  LTS_SUEDTIROL_GUEST_PASS = "lts_suedtirol_guest_pass",
}
