import { StatusCodes } from "http-status-codes";
import { GenericFailure, GenericFailureParams } from "../../errors/GenericFailure";

type LanguageErrorParams = Pick<GenericFailureParams, "logContext" | "message">;
export class LanguageError extends GenericFailure {
  override name = "LanguageError";

  constructor({ message, logContext }: LanguageErrorParams) {
    super({
      statusCode: StatusCodes.INTERNAL_SERVER_ERROR,
      message,
      logContext,
    });
  }
}
