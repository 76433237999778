export enum Icon {
  ADD_SELECTION = "add-selection.svg",
  ADD = "add.svg",
  ALARM_CLOCK = "alarm-clock.svg",
  ALERT = "alert.svg",
  BARCODE = "barcode.svg",
  BARS_CHART = "bars-chart.svg",
  BOSS = "boss.svg",
  BROWSER = "browser.svg",
  CALENDAR_DAY = "calendar-day.svg",
  CALENDAR_ROOMS = "calendar-rooms.svg",
  CALENDAR = "calendar.svg",
  CASH_REGISTER = "cash-register.svg",
  CHECK = "check.svg",
  CHECK_V2 = "check_v2.svg",
  CHECKLIST = "checklist.svg",
  CLIPBOARD = "clipboard.svg",
  CLOSE_V2 = "close_v2.svg",
  COLLABORATION = "collaboration.svg",
  COMPANY = "company.svg",
  CONTRACT = "contract.svg",
  COPY = "copy.svg",
  CREATE_FOLDER = "create-folder.svg",
  CROSS = "cross.svg",
  CSS = "css.svg",
  CUSTOMER_SERVICE = "customer-service.svg",
  DEBIT_CARD = "debit-card.svg",
  DELETE = "delete.svg",
  DIARY = "diary.svg",
  DOUBLE_NEXT = "double_next.svg",
  DOUBLE_PREV = "double_prev.svg",
  DOWN_ARROW = "down-arrow.svg",
  DOWNLOAD = "download.svg",
  EMAIL_CLOSED_WHITE = "email-closed-white.svg",
  EMAIL_CLOSED = "email-closed.svg",
  EMAIL = "email.svg",
  EMPTY_STAR = "empty_star.svg",
  FILTER = "filter.svg",
  FOLDER = "folder.svg",
  GALLERY = "gallery.svg",
  GUEST = "guest.svg",
  HAMBURGER = "hamburger.svg",
  HIGHER_LEVEL = "higher-level.svg",
  HOLIDAY = "holiday.svg",
  HOME_FOLDER = "home-folder.svg",
  HOME_ICON_SILHOUETTE = "home-icon-silhouette.svg",
  HOME = "home.svg",
  HOTEL_SETTINGS = "hotel-settings.svg",
  INFO = "info.svg",
  LINK = "link.svg",
  LOADER = "loader.svg",
  LOADING = "loading.svg",
  LOG = "log.svg",
  LOGOUT = "logout.svg",
  MAP_PIN = "map-pin.svg",
  MEDIA_UPLOAD = "media-upload.svg",
  MENU = "menu.svg",
  MONEY = "money.svg",
  NETWORK = "network.svg",
  NETWORKING = "networking.svg",
  NEXT = "next.svg",
  NOTES_BOOK = "notes-book.svg",
  OFFER = "offer.svg",
  OFFICE_FOLDERS = "office-folders.svg",
  OFFICE_MATERIAL = "office-material.svg",
  OPEN = "open.svg",
  PENCIL = "pencil.svg",
  PHONE_RECEIVER = "phone-receiver.svg",
  POLICEMAN = "policeman.svg",
  POSTBOX = "postbox.svg",
  PREV = "prev.svg",
  PREVIEW = "preview.svg",
  PRICE_LIST = "price-list.svg",
  QUALITY = "quality.svg",
  QUESTION_MARK = "question-mark.svg",
  REFRESH = "refresh.svg",
  REMOVE_SELECTION = "remove-selection.svg",
  RENAME = "rename.svg",
  REPORT = "report.svg",
  RIGHT_ARROW = "right-arrow.svg",
  ROOM_SERVICE_TRAY = "room-service-tray.svg",
  ROOM_SERVICE = "room-service.svg",
  SEARCH = "search.svg",
  SETTINGS_DOUBLE = "settings-double.svg",
  SHEET_WRITING = "sheet-writing.svg",
  SHIELD_NOT_OK = "shield-not-ok.svg",
  SINGLE_BED = "single-bed.svg",
  STAR = "star.svg",
  STOPWATCH = "stopwatch.svg",
  SUCCESS = "success.svg",
  TEAM = "team.svg",
  THEMES = "themes.svg",
  TRANSACTION = "transaction.svg",
  TWIN_BEDS = "twin-beds.svg",
  UP_ARROW = "up-arrow.svg",
  VAT = "vat.svg",
  VIEW = "view.svg",
  WAREHOUSE = "warehouse.svg",
  WARNING = "warning.svg",
  WINDOW_SETTINGS = "window-settings.svg",
}
