import { ref } from "vue";
import { useCommon } from "../../composition-functions/common";
import { Portal } from "../../composition-functions/portal/Portal";
import { PortalType } from "../../composition-functions/portal/PortalType";
import { PortalsDto } from "../../composition-functions/portal/PortalsDto";
import { IDeletePortalParams, IGetPortalParams, IGetPortalsParams, ISavePortalParams } from "./IParams";

const currentPortal = ref<null | Portal>(null);

async function deletePortal({ portalId }: IDeletePortalParams) {
  const { deleteRequest } = useCommon();
  await deleteRequest({ path: `/admin/portals/${portalId}` });
}

async function getPortal({ portalId }: IGetPortalParams): Promise<Portal> {
  const { getRequest } = useCommon();

  const response = await getRequest({ path: `/admin/portals/${portalId}` });
  const result = await response.json();
  return result;
}

async function refreshCurrentPortal(params: IGetPortalParams): Promise<void> {
  currentPortal.value = await getPortal(params);
}

async function getPortals({ currentPage, pageSize, searchTerm }: IGetPortalsParams): Promise<PortalsDto> {
  const { getRequest } = useCommon();

  const urlSearchParams = new URLSearchParams({
    searchTerm,
    currentPage: currentPage.toString(),
    pageSize: pageSize.toString(),
  });

  const response = await getRequest({ path: "/admin/portals", urlSearchParams });
  const result = await response.json();
  return result;
}

async function getPortalTypes(): Promise<PortalType[]> {
  const { getRequest } = useCommon();

  const response = await getRequest({ path: "/admin/portals/portalTypes" });
  const result = await response.json();
  return result;
}

async function savePortal({ portal }: ISavePortalParams): Promise<{ id: number }> {
  const { postRequest } = useCommon();

  const response = await postRequest({ path: "/admin/portals", data: JSON.stringify(portal) });
  const result = await response.json();
  return result;
}

export const usePortal = () => {
  return {
    deletePortal,
    getPortal,
    getPortals,
    getPortalTypes,
    savePortal,
    refreshCurrentPortal,
    currentPortal,
  };
};
