import { StatusCodes } from "http-status-codes";
import { useRegisterSW } from "virtual:pwa-register/vue";
import { ref } from "vue";

const intervalForUpdateIsInstallingInMilliseconds = 1000;
const intervalForUpdateCheckInMilliseconds = 1000 * 60 * 5;

export const isInstalling = ref(false);

export const { needRefresh: isRefreshNeeded, updateServiceWorker } = useRegisterSW({
  onOfflineReady() {
    // eslint-disable-next-line no-console
    console.log("App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB");
  },
  async onNeedRefresh() {
    // eslint-disable-next-line no-console
    console.log("Service worker needs an update");
  },
  async onRegisteredSW(serviceWorkerUrl, worker) {
    // eslint-disable-next-line no-console
    console.log("Service worker has been registered.");
    await checkForUpdates(serviceWorkerUrl, worker);
    setInterval(() => {
      if (!worker) {
        return;
      }
      isInstalling.value = !!worker.installing;
    }, intervalForUpdateIsInstallingInMilliseconds);

    setInterval(() => {
      checkForUpdates(serviceWorkerUrl, worker);
    }, intervalForUpdateCheckInMilliseconds);
  },
  onRegisterError(error) {
    // eslint-disable-next-line no-console
    console.error("Error during service worker registration:", error);
  },
});

async function checkForUpdates(serviceWorkerUrl: string, worker: undefined | ServiceWorkerRegistration) {
  if (!worker) return;

  if (worker.installing || !navigator) return;

  if ("connection" in navigator && !navigator.onLine) return;

  try {
    const response = await fetch(serviceWorkerUrl, {
      cache: "no-store",
      headers: {
        cache: "no-store",
        "cache-control": "no-cache",
      },
    });

    if (response?.status === StatusCodes.OK) {
      await worker.update();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("Error during service worker update:", error);
  }
}
