Array.prototype.unique = function <T>(property?: keyof T) {
  const result: T[] = [];
  for (const item of this) {
    if (typeof item === "object") {
      if (property !== undefined && result.find((result_item) => result_item[property] === item[property]) === undefined) {
        result.push(item);
      }
    } else if (result.includes(item) === false) {
      result.push(item);
    }
  }

  return result;
};
