export enum LanguageIsoCode {
  ABKHAZIAN = "ab",
  AFAR = "aa",
  AFRIKAANS = "af",
  AKAN = "ak",
  ALBANIAN = "sq",
  AMHARIC = "am",
  ARABIC = "ar",
  ARAGONESE = "an",
  ARMENIAN = "hy",
  ASSAMESE = "as",
  AVARIC = "av",
  AVESTAN = "ae",
  AYMARA = "ay",
  AZERBAIJANI = "az",
  BAMBARA = "bm",
  BASHKIR = "ba",
  BASQUE = "eu",
  BELARUSIAN = "be",
  BENGALI = "bn",
  BIHARI_LANGUAGES = "bh",
  BISLAMA = "bi",
  BOKM_L_NORWEGIAN_NORWEGIAN_BOKM_L = "nb",
  BOSNIAN = "bs",
  BRETON = "br",
  BULGARIAN = "bg",
  BURMESE = "my",
  CATALAN_VALENCIAN = "ca",
  CENTRAL_KHMER = "km",
  CHAMORRO = "ch",
  CHECHEN = "ce",
  CHICHEWA_CHEWA_NYANJA = "ny",
  CHINESE = "zh",
  CHURCH_SLAVIC_OLD_SLAVONIC_CHURCH_SLAVONIC_OLD_BULGARIAN_OLD_CHURCH_SLAVONIC = "cu",
  CHUVASH = "cv",
  CORNISH = "kw",
  CORSICAN = "co",
  CREE = "cr",
  CROATIAN = "hr",
  CZECH = "cs",
  DANISH = "da",
  DIVEHI_DHIVEHI_MALDIVIAN = "dv",
  DUTCH_FLEMISH = "nl",
  DZONGKHA = "dz",
  ENGLISH = "en",
  ESPERANTO = "eo",
  ESTONIAN = "et",
  EWE = "ee",
  FAROESE = "fo",
  FIJIAN = "fj",
  FINNISH = "fi",
  FRENCH = "fr",
  FULAH = "ff",
  GAELIC_SCOTTISH_GAELIC = "gd",
  GALICIAN = "gl",
  GANDA = "lg",
  GEORGIAN = "ka",
  GERMAN = "de",
  GREEK_MODERN_1453_ = "el",
  GUARANI = "gn",
  GUJARATI = "gu",
  HAITIAN_HAITIAN_CREOLE = "ht",
  HAUSA = "ha",
  HEBREW = "he",
  HERERO = "hz",
  HINDI = "hi",
  HIRI_MOTU = "ho",
  HUNGARIAN = "hu",
  ICELANDIC = "is",
  IDO = "io",
  IGBO = "ig",
  INDONESIAN = "id",
  INTERLINGUA_INTERNATIONAL_AUXILIARY_LANGUAGE_ASSOCIATION_ = "ia",
  INTERLINGUE_OCCIDENTAL = "ie",
  INUKTITUT = "iu",
  INUPIAQ = "ik",
  IRISH = "ga",
  ITALIAN = "it",
  JAPANESE = "ja",
  JAVANESE = "jv",
  KALAALLISUT_GREENLANDIC = "kl",
  KANNADA = "kn",
  KANURI = "kr",
  KASHMIRI = "ks",
  KAZAKH = "kk",
  KIKUYU_GIKUYU = "ki",
  KINYARWANDA = "rw",
  KIRGHIZ_KYRGYZ = "ky",
  KOMI = "kv",
  KONGO = "kg",
  KOREAN = "ko",
  KUANYAMA_KWANYAMA = "kj",
  KURDISH = "ku",
  LAO = "lo",
  LATIN = "la",
  LATVIAN = "lv",
  LIMBURGAN_LIMBURGER_LIMBURGISH = "li",
  LINGALA = "ln",
  LITHUANIAN = "lt",
  LUBA_KATANGA = "lu",
  LUXEMBOURGISH_LETZEBURGESCH = "lb",
  MACEDONIAN = "mk",
  MALAGASY = "mg",
  MALAY = "ms",
  MALAYALAM = "ml",
  MALTESE = "mt",
  MANX = "gv",
  MAORI = "mi",
  MARATHI = "mr",
  MARSHALLESE = "mh",
  MONGOLIAN = "mn",
  NAURU = "na",
  NAVAJO_NAVAHO = "nv",
  NDEBELE_NORTH_NORTH_NDEBELE = "nd",
  NDEBELE_SOUTH_SOUTH_NDEBELE = "nr",
  NDONGA = "ng",
  NEPALI = "ne",
  NORTHERN_SAMI = "se",
  NORWEGIAN = "no",
  NORWEGIAN_NYNORSK_NYNORSK_NORWEGIAN = "nn",
  OCCITAN_POST_1500_ = "oc",
  OJIBWA = "oj",
  ORIYA = "or",
  OROMO = "om",
  OSSETIAN_OSSETIC = "os",
  PALI = "pi",
  PANJABI_PUNJABI = "pa",
  PERSIAN = "fa",
  POLISH = "pl",
  PORTUGUESE = "pt",
  PUSHTO_PASHTO = "ps",
  QUECHUA = "qu",
  ROMANIAN_MOLDAVIAN_MOLDOVAN = "ro",
  ROMANSH = "rm",
  RUNDI = "rn",
  RUSSIAN = "ru",
  SAMOAN = "sm",
  SANGO = "sg",
  SANSKRIT = "sa",
  SARDINIAN = "sc",
  SERBIAN = "sr",
  SHONA = "sn",
  SICHUAN_YI_NUOSU = "ii",
  SINDHI = "sd",
  SINHALA_SINHALESE = "si",
  SLOVAK = "sk",
  SLOVENIAN = "sl",
  SOMALI = "so",
  SOTHO_SOUTHERN = "st",
  SPANISH_CASTILIAN = "es",
  SUNDANESE = "su",
  SWAHILI = "sw",
  SWATI = "ss",
  SWEDISH = "sv",
  TAGALOG = "tl",
  TAHITIAN = "ty",
  TAJIK = "tg",
  TAMIL = "ta",
  TATAR = "tt",
  TELUGU = "te",
  THAI = "th",
  TIBETAN = "bo",
  TIGRINYA = "ti",
  TONGA_TONGA_ISLANDS_ = "to",
  TSONGA = "ts",
  TSWANA = "tn",
  TURKISH = "tr",
  TURKMEN = "tk",
  TWI = "tw",
  UIGHUR_UYGHUR = "ug",
  UKRAINIAN = "uk",
  URDU = "ur",
  UZBEK = "uz",
  VENDA = "ve",
  VIETNAMESE = "vi",
  VOLAP_K = "vo",
  WALLOON = "wa",
  WELSH = "cy",
  WESTERN_FRISIAN = "fy",
  WOLOF = "wo",
  XHOSA = "xh",
  YIDDISH = "yi",
  YORUBA = "yo",
  ZHUANG_CHUANG = "za",
  ZULU = "zu",
}
