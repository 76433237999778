import { getGlobalTranslation } from "../../i18n";
import { GenericFailure } from "../GenericFailure";

export async function assertIfResponseOk(response: Response) {
  let genericFailure: undefined | GenericFailure;

  try {
    if (response.ok) {
      return;
    }

    const jsonBody = await response.json();

    genericFailure = new GenericFailure({
      statusCode: response.status,
      message: jsonBody.message,
      errors: jsonBody.errors,
      description: jsonBody.description,
    });
  } catch (_error) {
    genericFailure = new GenericFailure({
      statusCode: response.status,
      message: getGlobalTranslation({ message: "message.somethingWentWrong" }),
    });
  }

  throw genericFailure;
}
