import { createI18n } from "vue-i18n";

import dayjs from "dayjs";
import locale_de from "dayjs/locale/de";
import locale_it from "dayjs/locale/it";
import { SUPPORTED_LANGUAGES } from "../common/language/service.language";
import { Language } from "../composition-functions/language/Language";
import { LanguageIsoCode } from "../composition-functions/language/LanguageIsoCode";
import { mapLanguageToLanguageIsoCode } from "../composition-functions/language/LanguageMapper";
import de from "../i18n/de.json";
import it from "../i18n/it.json";
import { IGetGlobalTranslationParams } from "./IParams";

const i18n_FALLBACK_LANGUAGE = SUPPORTED_LANGUAGES.frontend[0];

export const i18n = createI18n({
  legacy: false,
  locale: i18n_FALLBACK_LANGUAGE,
  fallbackLocale: i18n_FALLBACK_LANGUAGE,
  messages: {
    [LanguageIsoCode.GERMAN]: de,
    [LanguageIsoCode.ITALIAN]: it,
  },
});

export function changeI18nLanguage(language: Language) {
  let languageIsoCode = mapLanguageToLanguageIsoCode(language) as null | SUPPORTED_LANGUAGES["frontend"];
  if (!languageIsoCode || !SUPPORTED_LANGUAGES.frontend.includes(languageIsoCode)) {
    languageIsoCode = i18n_FALLBACK_LANGUAGE;
  }

  i18n.global.locale.value = languageIsoCode;
  dayjs.locale(language === Language.ITALIAN ? locale_it : locale_de);
}

export function getGlobalTranslation({ message, parameters }: IGetGlobalTranslationParams): string {
  return parameters !== undefined ? i18n.global.t(message, parameters) : i18n.global.t(message);
}
