<template>
  <button
    :title="props.tooltipText !== undefined ? `${props.tooltipText}` : ''"
    :class="{ 'pointer-events-none': props.disabled }"
    class="h-full min-h-[1rem]"
  >
    <slot name="icon">
      <BaseIcon
        class="h-full"
        :class="{
          grayscale: props.disabled,
        }"
        :icon="props.icon"
      />
    </slot>
  </button>
</template>

<script setup lang="ts">
import { Icon } from "../assets/icons/Icon";
import BaseIcon from "../components/BaseIcon.vue";

const props = defineProps<{
  icon: Icon;
  tooltipText?: string;
  disabled?: boolean;
}>();
</script>
