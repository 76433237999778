<template>
  <div
    v-if="unreadNotificationCount !== null"
    class="px-sm rounded-full py-1 text-sm text-black"
    :class="{
      'border-primary bg-primary hover:bg-primary/20 hover:text-primary dark:hover:text-darkPrimaryTextColor border text-white':
        unreadNotificationCount > 0,
      'border border-gray-800 bg-gray-200 hover:bg-gray-300': unreadNotificationCount === 0,
    }"
  >
    {{ unreadNotificationCount }}
  </div>
  <div v-else>
    <BaseIcon class="h-md" :icon="Icon.LOADER" />
  </div>
</template>

<script setup lang="ts">
import { Icon } from "../../assets/icons/Icon";
import BaseIcon from "../../components/BaseIcon.vue";
import { useNotifications } from "../../notification/service.notification";

const { unreadNotificationCount } = useNotifications();
</script>
