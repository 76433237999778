import { CaptureContext } from "@sentry/types";
import { StatusCodes } from "http-status-codes";
import { IGenericFailureResult } from "../interfaces/IResults";

export interface GenericFailureParams {
  statusCode: StatusCodes;
  message: string;
  messageWithSensitiveData?: undefined | string;
  errors?: unknown;
  description?: undefined | string;
  logContext?: undefined | CaptureContext;
}

export class GenericFailure extends Error implements IGenericFailureResult {
  override name = "GenericFailure";
  success = false as const;
  statusCode: StatusCodes;
  messageWithSensitiveData: string;
  errors?: unknown;
  description?: undefined | string;
  logContext?: undefined | CaptureContext;
  constructor({ statusCode, message, errors, description, logContext, messageWithSensitiveData }: GenericFailureParams) {
    super(message);
    this.messageWithSensitiveData = messageWithSensitiveData ?? message;
    this.statusCode = statusCode;
    this.errors = errors;
    this.description = description;
    this.logContext = logContext;
  }
}
