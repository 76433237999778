import { getCsrfTokenFromLocalStorage } from "../composition-functions/csrfToken";
import { config } from "../config/config";
import { assertIfResponseOk } from "../errors/utils/assertIfResponseOk";
import { isNullOrWhitespace } from "../util/isNullOrWhitespace";
import { useUser } from "./user";

async function deleteRequest({
  path,
  data,
  urlSearchParams,
}: {
  path: string;
  data?: null | BodyInit;
  urlSearchParams?: URLSearchParams;
}) {
  const { userLanguageIsoCode } = useUser();

  const endpointUrl = new URL(config.apiHost + path);

  if (urlSearchParams !== undefined) {
    urlSearchParams.forEach((urlParameter, key) => {
      if (isNullOrWhitespace(urlParameter) === false) {
        endpointUrl.searchParams.append(key, urlParameter);
      }
    });
  }

  const requestOptions: undefined | RequestInit = {
    method: "DELETE",
    headers: {
      "Accept-Language": userLanguageIsoCode.value,
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": getCsrfTokenFromLocalStorage(),
    },
    credentials: "include",
    cache: "no-store",
  };

  if (data !== undefined) {
    requestOptions.body = data;
  }

  const response = await fetch(endpointUrl, requestOptions);

  await assertIfResponseOk(response);

  return response;
}

async function getRequest({ path, urlSearchParams }: { path: string; urlSearchParams?: URLSearchParams }) {
  const { userLanguageIsoCode } = useUser();

  const endpointUrl = new URL(config.apiHost + path);

  if (urlSearchParams !== undefined) {
    urlSearchParams.forEach((urlParameter, key) => {
      if (isNullOrWhitespace(urlParameter) === false) {
        endpointUrl.searchParams.append(key, urlParameter);
      }
    });
  }

  const response = await fetch(endpointUrl.toString(), {
    method: "GET",
    headers: {
      "Accept-Language": userLanguageIsoCode.value,
      "X-CSRF-TOKEN": getCsrfTokenFromLocalStorage(),
    },
    credentials: "include",
    cache: "no-store",
  });

  await assertIfResponseOk(response);

  return response;
}

async function postRequest({
  path,
  data,
  urlSearchParams,
}: {
  path: string;
  data?: null | BodyInit;
  urlSearchParams?: URLSearchParams;
}) {
  const { userLanguageIsoCode } = useUser();

  const endpointUrl = new URL(config.apiHost + path);

  if (urlSearchParams !== undefined) {
    urlSearchParams.forEach((urlParameter, key) => {
      if (isNullOrWhitespace(urlParameter) === false) {
        endpointUrl.searchParams.append(key, urlParameter);
      }
    });
  }

  const requestOptions: undefined | RequestInit = {
    method: "POST",
    headers: {
      "Accept-Language": userLanguageIsoCode.value,
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": getCsrfTokenFromLocalStorage(),
    },
    credentials: "include",
    cache: "no-store",
  };

  if (data !== undefined) {
    requestOptions.body = data;
  }

  const response = await fetch(endpointUrl.toString(), requestOptions);

  await assertIfResponseOk(response);

  return response;
}

async function putRequest({
  path,
  data,
  keepalive = false,
  urlSearchParams,
}: {
  path: string;
  data?: null | BodyInit;
  keepalive?: undefined | boolean;
  urlSearchParams?: URLSearchParams;
}) {
  const { userLanguageIsoCode } = useUser();

  const endpointUrl = new URL(config.apiHost + path);

  if (urlSearchParams !== undefined) {
    urlSearchParams.forEach((urlParameter, key) => {
      if (isNullOrWhitespace(urlParameter) === false) {
        endpointUrl.searchParams.append(key, urlParameter);
      }
    });
  }

  const requestOptions: undefined | RequestInit = {
    method: "PUT",
    headers: {
      "Accept-Language": userLanguageIsoCode.value,
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": getCsrfTokenFromLocalStorage(),
    },
    credentials: "include",
    cache: "no-store",
    keepalive,
  };

  if (data !== undefined) {
    requestOptions.body = data;
  }

  const response = await fetch(endpointUrl, requestOptions);

  await assertIfResponseOk(response);

  return response;
}

export const useCommon = () => {
  return {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
  };
};
