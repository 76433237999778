<template>
  <GlobalEvents @keydown.alt.home.prevent="router.push({ name: 'home' })" @keydown.alt.left.prevent="router.back()" />

  <div class="grid auto-rows-[50px] grid-cols-1 gap-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4" v-bind="$attrs">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";

const router = useRouter();
</script>
