export enum Language {
  NONE = 0,
  GERMAN = 1,
  ITALIAN = 2,
  ENGLISH = 3,
  DUTCH = 4,
  RUSSIAN = 5,
  POLISH = 6,
  HUNGARIAN = 7,
  CZECH = 8,
  AUSTRIAN = 9,
  SWISS = 10,
  BELGIAN = 11,
  DANISH = 12,
  FRENCH = 13,
  CROATIAN = 14,
  LIECHTENSTEIN = 15,
  SWEDISH = 16,
}
