export const config = {
  nodeEnv: import.meta.env.NODE_ENV,
  baseUrl: import.meta.env.BASE_URL,
  apiHost: import.meta.env.VITE_APP_API_HOST,
  documentationBaseUrl: import.meta.env.VITE_APP_DOCUMENTATION_BASE_URL,
  widgetsBaseUrl: import.meta.env.VITE_APP_WIDGETS_BASE_URL,
  eassistantProductionUrl: import.meta.env.VITE_APP_EASSISTANT_HOST,
  isProduction: import.meta.env.PROD === true,
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
  },
};
